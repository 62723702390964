.chatbot {
  position: fixed;
  margin-left: 15px;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  z-index: 100;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
}
